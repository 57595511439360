import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Tasker - Add a Task to activate Motion Detection",
  "path": "/Advanced_User/Tasker/Motion_Detection_Add_Task/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Use a macro tool to automate functions of your camera",
  "image": "../AU_SearchThumb_Tasker.png",
  "social": "/images/Search/AU_SearchThumb_Tasker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Tasker_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Tasker - Add a Task to activate Motion Detection' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use a macro tool to automate functions of your camera' image='/images/Search/AU_SearchThumb_Tasker.png' twitter='/images/Search/AU_SearchThumb_Tasker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Tasker/' locationFR='/fr/Advanced_User/Tasker/' crumbLabel="Tasker" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "tasker---add-a-task-to-activate-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tasker---add-a-task-to-activate-motion-detection",
        "aria-label": "tasker   add a task to activate motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tasker - Add a Task to activate Motion Detection`}</h1>
    <h2 {...{
      "id": "how-do-you-activate-motion-detection-when-you-are-at-home",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-do-you-activate-motion-detection-when-you-are-at-home",
        "aria-label": "how do you activate motion detection when you are at home permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do you activate motion detection when you are at home?`}</h2>
    <h4 {...{
      "id": "add-a-task-motion-detection-enabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#add-a-task-motion-detection-enabled",
        "aria-label": "add a task motion detection enabled permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a task (motion detection enabled)`}</h4>
    <p>{`Start Tasker and switch to the Task tab. Press the plus down in the middle.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7af8cc128c541c5ff74af73db7bc2573/772e8/Tasker-(1).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAABlUlEQVQ4y+2OTW/aQBCG9/9feoxUrgghVFUcckB1fCBSYnt3Z9cJxTGLFxts/LGum7QqYFBwKoxKXTVSK/VUiUdzm/eZd5CUs/GIT5w7OfOl9ORf4Hme67pSSiS8gF2+GVkDfx7NpJz9Cd/3hRCc8yAIkBCT8ehuMhGe502nU9FgWiN+5RhzXVcIgQCAMWbVYIwBgAIQYJiCaWETY0wZAUbhAKX0FAMAxBgzDKPb7XY6nX6/zzhnlIwYuQdy+f5dv9e1ifmREyCY27au6+12u9frHX10vMFqAJgNVCf84sZp3Tot46FlPLy9dS5unA/Ytg8/nZJ186Zm/YNys/m6LuPVLlntkm/bw6x28Wr3ZV2WjdjRQi//wFk+y2f5LP+fclVVB3m/31dV9fwaVVlW2+3rq1pBURQFQaCUypoo9fnqKtG0eDB41LS0KLI0be5d143jGC0WC9M05/N5nufpKZEkj8Ph2HHubftJ19M8b8pKKcuywjBESqkoisIw/FmeplmeF8NhoeufNK24vv69eblcZln2HZNeH6cGRq1vAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7af8cc128c541c5ff74af73db7bc2573/f13a9/Tasker-(1).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7af8cc128c541c5ff74af73db7bc2573/ba381/Tasker-(1).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7af8cc128c541c5ff74af73db7bc2573/772e8/Tasker-(1).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7af8cc128c541c5ff74af73db7bc2573/772e8/Tasker-(1).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Start the Android app and press the + at the bottom.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/68640d2dc0be2e2682d7a82e8d438c9f/772e8/Tasker-(2).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "158.50000000000003%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAACCUlEQVQ4y+2UW2/TMBiG8/8v+AcwgVStaCAxQAKtrKCtWZ24cdqGNofmRB3nS1K6Js6BTVpT1HadKm0IJG776JP16rUf2VcWHMcd62PLHDmu6/wbtm1bluU4jmBMPPLx2RB9dn3q2PZfTdd1TdNUFMXzPGEymYz6sqHrkw32Ho+bbWlZlmmatm0LhBB1MCR7KIT0NoOV+9DblPe7yjqqqkoIWcsIoWazeXx8/P70dF33sE56IwV/evf2w5vXGpZ0taf2sKqq7Xa70WicnJzIsryWMcayLKMdBOO2pBx1tFei9uKy//xCfSlqR6J2hgjBWJLlbreLEMIbhHyfoihyPuOlkd6Ye6OnNzEvi5zfH9khrP6Dg3yQD/JB/pNc1/XdhuVyebdj+Yi6rp+Qq6rKMl4URcZ5WVZV9asoy8UDaco5z7Ls6T/s9vbWGI++tM5MQ5clJKHuxbevwfRHmfNscZ2mKWPMsqw0TYW6rler1fYZ61zXvChHji8NtL4xORfRuYhanSubASz4dHad5XlAKQCsbwYAxth8Pp/tCKMIFlnCizjLZ3mZ8OJnUXkQG97UpiENWBRBFEVBEAi+70uSRClNkiTeEIZhDBBBGLKAURqF4SyOvw+HncuL4aDf6XREUWy1WpqmCUmSuK47nU4fZAAIgoAx5vu+53mMMUppGIYAsF0BwPd9APgNpnYTFJzvqCwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/68640d2dc0be2e2682d7a82e8d438c9f/f13a9/Tasker-(2).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/68640d2dc0be2e2682d7a82e8d438c9f/ba381/Tasker-(2).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/68640d2dc0be2e2682d7a82e8d438c9f/772e8/Tasker-(2).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/68640d2dc0be2e2682d7a82e8d438c9f/772e8/Tasker-(2).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Name the task and confirm the entry.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f12b3f37ed34d4b69ab0ee59e71fb6e3/772e8/Tasker-(3).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "158.50000000000003%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAYAAAASYli2AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABsUlEQVRIx+2WvUvDUBTF86+6OIqgg6ODiKiTIIJTu+pQcFRph7RDSltJY2LTtKWl4eUljfSDfqlJj9yn0VoRBeMg9MHh3vdy7++dkCFXsqwaDF3DnVGGouSRzWahaRqazSbq9TpqtZqI8zlFwzA+nFerVZFLN+otihcHKCtXuLzO4PzsDIqiwDRNWJb11rSoTCaDfD6PVquFSqWCQqGARqMBqWqa0NUidN0Q7kqlEnK5HNLptHBBtxN4XnRGEHqTyB0ZEA7H4zEm0weIOJmIOJ1OhUaj0ZeK6qM95dQjIeYlzWYzxKlPQFpR/G7N138Azh/+GrgI+Cnsq/q/cRjrV14Cl8AlcAn8p8DYf1KxOwzDUJCDIBCifZQHlD8+Iuz1EA4GCJ6e3p8tiBjUK7XbbZA6nQ4453AcB67rwiPd+/C3d+CSNrbgn5zCHQ7hcQ7P895E9TRcUa8kyzISiQS63S5UVUUqlRLzC13g2Db83X2ojKEsy/CPjsH7fXDGxOX8FUwDUzKZFP0SbYhODxhjsG37xSU1+D78tQ0465twVlbR2TsEHw7hMiZcRaJ6Gpqo9xlKtbjKNvV6LQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f12b3f37ed34d4b69ab0ee59e71fb6e3/f13a9/Tasker-(3).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f12b3f37ed34d4b69ab0ee59e71fb6e3/ba381/Tasker-(3).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f12b3f37ed34d4b69ab0ee59e71fb6e3/772e8/Tasker-(3).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f12b3f37ed34d4b69ab0ee59e71fb6e3/772e8/Tasker-(3).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Press the + again to open the Action Category dialogue.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1ef79b598028324ba601aa82876d7d95/772e8/Tasker-(4).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEv0lEQVQ4y41U23LaSBDly/0J8SaVfMLWxnnxBmEb4vUa21BlI8SKCHMXMmAEsq7MXaPLbEnD2tnLw55SqWZafWZafbq7cnx83Gxe1+v1RolvjUajXr7/2sp18f3g0ri4uLi+vn737l3l4vxcCEGTNM1Fmgue5Wmex1melds4y5NykeciK99CiDRNhRDn5+eV2tdfV5BX517jKehswj+24d06aD2Hd+twsIvUTfjbMvh9GTZXwd06nLxEGIIwjNI0PT09rZyfn2W58PYgADAACGEcQRyWD0AoBCiE2AXIjpBfGBHBCEJQ3Hx2Vvnla+0ZJ6YH1oAtIzL3gOkfnuWergEzfbDwgeXD0kKWe2b6cI34z6dKRVFqQojNxr5/eDDNBcYEAAggxJgMHh8NwygsEAGIEMLD0aijqpb1FMf85MuXSq1WkIMgGI/H2+2WEAJLMMZms9loNKKUSguldLlcGobheR5j7Isk53lOCBkMBmEYvrpyzm3bdl2XMfZ6nOd5i8WCc04IOTk5OZCDIHBdNwgCjDGERYhBEPglAAAQQgAAxtj3fcdxIIQHsqIoQojtdnt/f6/rOqUUAEApNU2z1Wrd3t5Op1NpZIxtt1tVVcMwZIy9kRFCmqY5jvPjH9q2vd1uMcYIIQhhHMez2azdbmOM325O0xRjvNlsAADS73UBi7RjUIIx5jjOeDxOkoQQ8vnz58PNq9VqOp2apik5CKEwDOfzuWmaYRiiErvd7unpyTTNyWSCECpurlarpc6bbrdrGIaUqvDFWO/3vxtGKXARuuu6w+Gw1+uNx+MDWVGUPM8ppZvNBiFUkDEGEKacjx4fJ6NRyjkoo2eM7fd7z/OSJKGUFmFXq9Usy4IgiKIoCkPIGHx6oo0GbbX2l5fw6oq2WvTiAlkWoNT3vFfZDmQhhGEYd3d3mqrCJMG6jns9ynlfVfudDuUcaxrR9SiOu6rabrcnkwnn/C1hEMJ+v7+zbZrnuN/HsxkVYuv7ThhSIfB0inWdCvHiOPLvDmEripJlGaV0Op0Gvk/zHBkGvryMTRPqeqRpsWmSb9/Q9+/FuWXO4zg+SCXDtixrXaLITRTR4TBQVevqat1s7jWNPD7iKHI9bzqZWJa1Wq3+JpVlWa1Wq9frFTphjLPMx7g3HPZGI6/cYkodx+l2u51OR9M0jPGbVHEcN5tN13XjOC7Kar/nlK6XS/v5OaYU7veyPBFClmVxzhljh7Blefq+L8tBFqNsLFle0iKLR346SCWz3S3R6XQ8z5NFRgjRdb3b7UpvCV3Xb29v/ymV4ziDwWCz2cjjZesvl0vLsl77jBDiOI6u677vv7VknueMsfl8LjmvLbnb7V5eXn6cJL7vT6fTJEnewuacr9dr13VXq5XnefLmKIqcElEUyXBc17Vte7fbPT8/M8aKuX12dsYYuy9xc3NjmialVGZLVdWHhwc5myili8VC07Rut9tutymltVrtMD2TJEnTVLaLTCYhJC5BCJHDhFIaxzHnnFIqhFCUYm4rURTN53Pbtvf7vZRE4lWhH7cAgCiKsiyrVquVer0usx0EQZqm2f9AkiRCiHq9Xjk6Ovr06dP79+8/fPhw/C/89F84Pj7++PHj0dHRn69OnjyAjqy9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1ef79b598028324ba601aa82876d7d95/f13a9/Tasker-(4).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1ef79b598028324ba601aa82876d7d95/ba381/Tasker-(4).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1ef79b598028324ba601aa82876d7d95/772e8/Tasker-(4).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1ef79b598028324ba601aa82876d7d95/772e8/Tasker-(4).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Select the Net tile in the Category dialog.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b02c381ba7fa44e83d2899d67eba390/772e8/Tasker-(5).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFGklEQVQ4y41U23LTSBD1D1M85AJ8hXnIJ+wLm9iGQAJVQC1bpAzExBjbcmTLkm1JtiVlpBnNfRRJsyXZ3N72VD9opnSk7tN9unF8fHxyctJsNptPnzafVmj+iKe/PfyOZrN5cnLy+PHjRqfd1lrnRaH/N/I811q/fv260fr7mUezt0vwzwpeb2B/g94uk/4WftvCjx7s+rC3hjDFBP8CQijP83an0zh/3lFFaYN0mdA1Igmhy4RuEY0w9SD1Id0gKjgTnMkfwSnRWl9eXDT+OusEIncR9TF3U76qCMSFxEXMxdyrLtkiIVXEZBHjRULsO7QV+bMXrxqnrTbPcuBvkvW2ihjGmMSYJuFdst5Cf51EIMY0TklCGGI8wTQhVBb6+fnLvWB8bt1//qQGA5HEnDEmuFwus9519vWrdF3GueBsblnD798nhtG7vqaEvL68bLTb7VJrkiTUcdhmw4SghFDGOEJstaoCIcoYJZVkcRxHUQQAyPP85cuXNbkoMCEhAAhjRiuQmg8gBBAyzkkNzjkAYDKZUErLsqzIZ2dnZVkKITIplZRCCIwxYyxTStU3UgjGGCFESqmUyrKMMaa1Pj8/b3Q6HcbYt2/fTNMcj8eLxUIp5bruYDCYTqeTycQ0Tc/zMMaj0ciyrOl0OhqNgiC43NWcZZnjOL7vr1arMAyr9MJwMZ/7ruu77sb3QRQRQlarled5vu+7rosxfvXqVaPVammtlVK2badpqpQinNOiyLX2AfABuNeaFgXhPMuyNE1t2xZC7NM+OzvL85xSCgCghHClyHrNP37M+n3S7ZJuN+v3+dUV2W65UhjjJEkYY2VZVuR2u62UGg6Hk8lkPBza67Ucj3m3GywWG8MIbm/DxYJ9/sxGowDCzbqC53lSyr3aWmvTND98+PCp24Wci8mEmqbl+7bjzG17bNt0NmOGMfc8x7YdxxkMBhDCi4uLPZkx5jhOGARSazYasflcaZ1QGiKktKa2zcZjpTWjdLPZSCn/qBljvFwuYZKQLKOGIS4v78fj4N276P37bDQSb95Q06T393dRtFgsMMZFUVTkVqtVliXGWNTDwBjjjAWmOb++Xo9GoWku+/0722aU7oZHSokx/iWY53lXV1f9fn84HN7c3KRpCiDsD4f/Xl11v3z5OhgEd3dCiDiOe73e7p39bLdarTzPIYRRFCGE0jTFGEspGefz+dyyrN1xN9vVdwFACO1r3qXNGDNNEwAghKB1hlJK13U9z1N1eymlnPM4jk3T3BljL1hZlpxzKWWWVXYgPyBr/Dzu3lFKCSH25E6nQwjp9XqWZQ2HQ8uydpYUQozHY8MweG1JznkQBDv/GIaxV7vdbnPOHccBACyXS8/zakdXSW632zAMGas8zhiDEPq+H9Xd+pV2ZQwpZ9NpilCWZbs/K6W8Gj8LkVKmaWpZ1p9DUhRpvTdSSknte0qpUuqn8qTeQbuM0jSllOZ5XqvdbpdSUt+XUcSDgNWmgRAul8swDH3fX6/XCKHdMvE8b7VaRVH0o1WdjiaETqfS88Rkwh2H1S25ubnxff/29nYwGERRJIRACBmGMRgMZrPZntyulwGVEmJMpRRK7Ya0LEsAQJIkRVFwzimlUkrOeRiGu5qrCTs9Pc2UQkkSBgFMEpymCCEI4W7vrFYrQgiEECGEMY6iaDaboVrXFy9eNB48eHB8fHx4eHh0dHT4Gw4ODh7VODg4+Hl5dHT05MmTw8PDR48ePXz48D9dfJC46BPShwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b02c381ba7fa44e83d2899d67eba390/f13a9/Tasker-(5).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b02c381ba7fa44e83d2899d67eba390/ba381/Tasker-(5).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b02c381ba7fa44e83d2899d67eba390/772e8/Tasker-(5).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b02c381ba7fa44e83d2899d67eba390/772e8/Tasker-(5).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Then select the HTTP GET action.`}</li>
    </ol>
    <p>{`Now we have to define the CGI command that will be sent to the camera every time your smart phone connects to your home Wi-Fi network. Enter the local IP of your camera as the server address - do not forget to attach the web port of your camera if it does not correspond to 80. If the address of your camera is e.g. 192.168.178.200 and you have changed the local port to 8081 (in the web interface of the camera under Network / IP Configuration - and not the external port you may have passed in your router), you have 192.168.178.200:8081 as a server Specify address. After that, only the matching CGI command in the Path field is missing.`}</p>
    <p>{`The CGI command to enable all 4 motion detection areas of your INSTAR HD camera is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`/web/param.cgi?cmd=setmdattr&-name=1&-enable=1&cmd=setmdattr&-name=2&-enable=1&cmd=setmdattr&-name=3&-enable=1&cmd=setmdattr&-name=4&-enable=1?usr=admin&pwd=instar`}</code></pre></div>
    <p>{`Please change the username (usr) admin and password (pwd) instar according to your camera login.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bb51ca549b17005cc563463d8453f0c1/772e8/Tasker-(6).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAADiklEQVQ4y42UfW+iShTG+f7J/Rz9s3Xb3txtG2xsKtYi9IVV6mBRrICKKMwML/MCezso63V7u/3lhEzCnMzMec5zJACsH8/awNBv20pb0Ol0Li8vVVUdj8eWZY0Etm2rqjoYDGzbBgCYpjkajaSHpx/62V8PyvfWTfv8/KzRaMiyfHZ21uv16uTX11cAwLdv366vryeTiWmamqbZti0BAPrPTy8vw8FgoOv6w8ODqqqapo3HYwCAtYemaYZhjEYjAMBwOLQsS0IIYYwhhLEAYxxF0Wq1Wi6Xqx1hGK7Xa4RQtS1JkjRNsyyTZrOZLMs9QafTkWXZNM3NZhOG4UYQRVEcx0iQJAljbLFYNBqNJEmk+Xyu63pfYFmWruuO44RhGARBKFiv15vNJo5jCCFCiHNu2/b9/X1ZllJRFOWOas05L4qCUkoEdAcTVH+r/VJZlgnGnudihBbzebhaYYzd2SzPsvJ/KIqi4HybPHlzv19ePfcH/1xetW7bxsA8Pf87jGJWljnjhBd10F1kjLOikAgvFglZZtTHWZCxRUqqxTKlCxHLDyPnOSukPM8TjBCMEYzjKIqjCMbbL4xjGMcIQoxQgnGaJHmWUpIzSjGCZVlIjuMcHx9fXFx4vr8Kw2C1qmNbbSGYqPZ7T1DKHGd6dHSEMZbiOK5ad7lcBoJVnSZEigSVThhjxthsNhsOh4dSVXDO2W/wHYXgl1QQQtd1Pc8LgsA0TQhhrfknam2TPc/rCizL6vV6k8mEc04ISdMUC5IkIYRgjDNBnueMsW3ydDrtdrs3Nze6rnueZxhGt9t9eXlptVqdTufu7k6W5VarpShKv99XFOX29tb3/e2bD9qwevP+C6tWFX3F64pwziWyI89zQkiWZXBH5aTq2qkgyzJCCGOMUvp+suu6siw3m01FUZrNpqZp+zaujVm5EmNMKfV9/+Tk5N2SYRg+PT0ZhvH4+Kiqar/fD3esBZXU+zpPJhNN0z7QuS5Bzb4l60L80pkQghCqX/W5woc6R1EEAHh7e3McZz6fF1+gOv/92gdtSP9EPU+kLMuqOydJUo3Ommqe1qWqBMvznFIKIXxPnk6nV4LT09N2ux2G4YFUBwOQMeY4ztaSURSNRiPLsgAAtm0f6LRerz+z5O9l/NCJ+936H6nSNA2CoDoBIfRHqer8rZ9t2/Z933XdIAj419hKxcUQrmFf49/knxdEAIUiDtGlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb51ca549b17005cc563463d8453f0c1/f13a9/Tasker-(6).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bb51ca549b17005cc563463d8453f0c1/ba381/Tasker-(6).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb51ca549b17005cc563463d8453f0c1/772e8/Tasker-(6).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bb51ca549b17005cc563463d8453f0c1/772e8/Tasker-(6).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Enter the HTTP Get command that you want to send to the IP address of your camera.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b02c381ba7fa44e83d2899d67eba390/772e8/Tasker-(5).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFGklEQVQ4y41U23LTSBD1D1M85AJ8hXnIJ+wLm9iGQAJVQC1bpAzExBjbcmTLkm1JtiVlpBnNfRRJsyXZ3N72VD9opnSk7tN9unF8fHxyctJsNptPnzafVmj+iKe/PfyOZrN5cnLy+PHjRqfd1lrnRaH/N/I811q/fv260fr7mUezt0vwzwpeb2B/g94uk/4WftvCjx7s+rC3hjDFBP8CQijP83an0zh/3lFFaYN0mdA1Igmhy4RuEY0w9SD1Id0gKjgTnMkfwSnRWl9eXDT+OusEIncR9TF3U76qCMSFxEXMxdyrLtkiIVXEZBHjRULsO7QV+bMXrxqnrTbPcuBvkvW2ihjGmMSYJuFdst5Cf51EIMY0TklCGGI8wTQhVBb6+fnLvWB8bt1//qQGA5HEnDEmuFwus9519vWrdF3GueBsblnD798nhtG7vqaEvL68bLTb7VJrkiTUcdhmw4SghFDGOEJstaoCIcoYJZVkcRxHUQQAyPP85cuXNbkoMCEhAAhjRiuQmg8gBBAyzkkNzjkAYDKZUErLsqzIZ2dnZVkKITIplZRCCIwxYyxTStU3UgjGGCFESqmUyrKMMaa1Pj8/b3Q6HcbYt2/fTNMcj8eLxUIp5bruYDCYTqeTycQ0Tc/zMMaj0ciyrOl0OhqNgiC43NWcZZnjOL7vr1arMAyr9MJwMZ/7ruu77sb3QRQRQlarled5vu+7rosxfvXqVaPVammtlVK2badpqpQinNOiyLX2AfABuNeaFgXhPMuyNE1t2xZC7NM+OzvL85xSCgCghHClyHrNP37M+n3S7ZJuN+v3+dUV2W65UhjjJEkYY2VZVuR2u62UGg6Hk8lkPBza67Ucj3m3GywWG8MIbm/DxYJ9/sxGowDCzbqC53lSyr3aWmvTND98+PCp24Wci8mEmqbl+7bjzG17bNt0NmOGMfc8x7YdxxkMBhDCi4uLPZkx5jhOGARSazYasflcaZ1QGiKktKa2zcZjpTWjdLPZSCn/qBljvFwuYZKQLKOGIS4v78fj4N276P37bDQSb95Q06T393dRtFgsMMZFUVTkVqtVliXGWNTDwBjjjAWmOb++Xo9GoWku+/0722aU7oZHSokx/iWY53lXV1f9fn84HN7c3KRpCiDsD4f/Xl11v3z5OhgEd3dCiDiOe73e7p39bLdarTzPIYRRFCGE0jTFGEspGefz+dyyrN1xN9vVdwFACO1r3qXNGDNNEwAghKB1hlJK13U9z1N1eymlnPM4jk3T3BljL1hZlpxzKWWWVXYgPyBr/Dzu3lFKCSH25E6nQwjp9XqWZQ2HQ8uydpYUQozHY8MweG1JznkQBDv/GIaxV7vdbnPOHccBACyXS8/zakdXSW632zAMGas8zhiDEPq+H9Xd+pV2ZQwpZ9NpilCWZbs/K6W8Gj8LkVKmaWpZ1p9DUhRpvTdSSknte0qpUuqn8qTeQbuM0jSllOZ5XqvdbpdSUt+XUcSDgNWmgRAul8swDH3fX6/XCKHdMvE8b7VaRVH0o1WdjiaETqfS88Rkwh2H1S25ubnxff/29nYwGERRJIRACBmGMRgMZrPZntyulwGVEmJMpRRK7Ya0LEsAQJIkRVFwzimlUkrOeRiGu5qrCTs9Pc2UQkkSBgFMEpymCCEI4W7vrFYrQgiEECGEMY6iaDaboVrXFy9eNB48eHB8fHx4eHh0dHT4Gw4ODh7VODg4+Hl5dHT05MmTw8PDR48ePXz48D9dfJC46BPShwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b02c381ba7fa44e83d2899d67eba390/f13a9/Tasker-(5).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b02c381ba7fa44e83d2899d67eba390/ba381/Tasker-(5).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b02c381ba7fa44e83d2899d67eba390/772e8/Tasker-(5).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b02c381ba7fa44e83d2899d67eba390/772e8/Tasker-(5).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Leave all other settings - just go back to save the action.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      